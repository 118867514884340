//  组织架构相关
function organizationRouters() {
    return {
        "title": "内部运营管理",
        "path": "/OrganizationManage",
        "children": [
            {
                "title": "企业组织架构管理",
                "path": "/OrganizationManage/index",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/index.vue'),
                "children": [
                    {
                        "path":'',
                        component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/CompanyInfoPage.vue')
                    },
                    {
                        "path": "DepartmentInfoPage",
                        component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/DepartmentInfoPage.vue')
                    },
                ]
            },
            // {
            //     "title": "部门管理",
            //     "path": "/OrganizationManage/Admin/DepartmentManage",
            //     "isActivate": true,
            //     "role": [0],
            //     component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/Admin/DepartmentManage.vue')
            // },
            {
                "title": "角色管理",
                "path": "/OrganizationManage/Admin/RoleManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/Admin/RoleManage.vue')
            },
            {
                "title": "员工管理",
                "path": "/OrganizationManage/StaffListPage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/StaffListPage.vue')
            },
            {
                "title": "权限管理",
                "path": "/OrganizationManage/Admin/RoleManage",
                "isActivate": true,
                "role": [0],
                "children":[
                    {
                        "title": "功能权限",
                        "path": "/OrganizationManage/Admin/RoleManage",
                        "isActivate": true,
                        "role": [0],
                        component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/Admin/RoleManage.vue')
                    },
                    {
                "title": "菜单权限",
                "path": "/OrganizationManage/Admin/RoleManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/Admin/RoleManage.vue')
            },
            {
                "title": "数据权限",
                "path": "/OrganizationManage/Admin/RoleManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/Admin/RoleManage.vue')
            },
               ]
            },
            {
                "title": "菜单管理",
                "path": "/OrganizationManage/Admin/MenuManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/Admin/MenuManage.vue')
            },
            {
                "title": "提醒事件管理",
                "path": "/OrganizationManage/Admin/RoleManage",
                "isActivate": true,
                "role": [0],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/Admin/RoleManage.vue')
            },
            // {
            //     "title": "用户管理",
            //     "path": "/OrganizationManage/Admin/UserManage",
            //     "isActivate": true,
            //     "role": [0],
            //     component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/Admin/UserManage.vue')
            // },
            {
                "title": "部门管理",
                "path": "/OrganizationManage/DepartmentList",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/DepartmentList.vue')
            },
            {
                "title": "角色管理",
                "path": "/OrganizationManage/RoleList",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/RoleList.vue')
            },
            {
                "title": "用户管理",
                "path": "/OrganizationManage/UserList",
                "isActivate": true,
                "role": [1],
                component: () => import(/* webpackChunkName: "about" */ '@/views/OrganizationManage/UserList.vue')
            },
        ]
    }
}

export default organizationRouters;
